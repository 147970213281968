<template>
  <div ref="el" class="max-lg:hidden">
    <ClientOnly>
      <section-wrapper class="bg-primary overflow-visible px-3">
        <section-container
          v-if="authStore.isAuthenticated"
          mobile-padding
          class="max-w-container flex items-center justify-between gap-4 py-3"
        >
          <div>
            <a
              class="text-normal mr-5 font-bold text-white"
              href="tel:+33767473005"
            >
              <icon icon="tel" class="mr-3 h-6 w-6"></icon> 07 67 47 30 05
            </a>
            <a href="mailto:contact@funargos.fr" class="text-normal text-white">
              <icon icon="enveloppe" class="mr-3 h-5 w-5"></icon>
              contact@funargos.fr
            </a>
          </div>
    
          <router-link :to="{ name: 'less-demandes' }" v-if="authStore.company" style="margin-left:322px">
      
              <span class="mr-3 text-white">Les demandes de transport</span>
            
              <svg class="h-6 w-6 text-white icon-circle" version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 834.000000 834.000000"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,834.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M3913 6874 c-485 -57 -938 -279 -1293 -634 -401 -401 -627 -923 -628
-1455 0 -157 9 -233 48 -432 139 -697 456 -1375 922 -1974 217 -279 513 -592
673 -713 109 -81 284 -169 382 -190 318 -70 633 85 1025 505 396 424 717 924
992 1547 223 504 309 848 305 1227 -3 330 -69 612 -213 910 -294 604 -869
1048 -1526 1179 -228 46 -464 56 -687 30z m491 -440 c198 -31 364 -83 531
-166 504 -251 846 -707 945 -1258 28 -159 22 -480 -13 -640 -62 -282 -232
-715 -413 -1051 -216 -401 -501 -790 -817 -1116 -146 -150 -243 -228 -337
-272 -169 -78 -289 -32 -511 196 -689 708 -1163 1510 -1304 2203 -39 189 -48
299 -42 470 9 225 46 389 131 594 93 222 213 393 403 577 261 252 627 427 975
468 125 14 343 12 452 -5z"/>
<path d="M4042 5790 c-450 -57 -813 -373 -929 -808 -23 -85 -26 -117 -26 -257
-1 -113 4 -181 16 -230 90 -391 361 -690 737 -814 239 -79 544 -62 772 42 216
99 405 276 516 485 49 92 97 232 111 324 17 103 15 302 -4 393 -89 431 -416
760 -845 850 -90 19 -256 26 -348 15z m263 -445 c233 -49 430 -240 491 -475
21 -83 21 -225 0 -310 -59 -235 -253 -422 -492 -476 -155 -34 -352 1 -484 87
-120 77 -206 188 -258 331 -24 66 -27 86 -27 213 0 127 3 147 27 213 113 311
421 484 743 417z"/>
</g>
</svg>
         
          </router-link> 


          <Popover class="relative">
            <PopoverButton class="py-2" v-if="authStore.company">
              <span class="mr-3 text-white">{{ authStore.company.name }}</span>
              <icon icon="profil" class="h-6 w-6 text-white"></icon>
            </PopoverButton>
            <transition
              enter-active-class="transition duration-200 ease-out"
              enter-from-class="translate-y-1 opacity-0"
              enter-to-class="translate-y-0 opacity-100"
              leave-active-class="transition duration-150 ease-in"
              leave-from-class="translate-y-0 opacity-100"
              leave-to-class="translate-y-1 opacity-0"
            >
              <PopoverPanel
                v-slot="{ close }"
                class="text-neutral-light absolute z-10 flex min-w-[15rem] flex-col overflow-hidden rounded-3xl bg-white text-black drop-shadow-lg"
              >
                <NuxtLink
                  to="/dashboard"
                  @click="close()"
                  class="hover:bg-background-3 border-b px-5 py-3 text-[0.75rem]"
                >
                  <icon icon="profil" class="mr-3 h-4 w-4"></icon>
                  Mes informations
                </NuxtLink>

                <NuxtLink
                  to="/dashboard/mes-trajets-publies"
                  @click="close()"
                  class="hover:bg-background-3 border-b px-5 py-3 text-[0.75rem]"
                >
                  <icon icon="route" class="mr-3 h-4 w-4"></icon>
                  Mes trajets publiés
                </NuxtLink>

                <NuxtLink
                  to="/dashboard/mes-trajets-achetes"
                  @click="close()"
                  class="hover:bg-background-3 border-b px-5 py-3 text-[0.75rem]"
                >
                  <icon icon="route" class="mr-3 h-4 w-4"></icon>
                 Mes demandes de trajets
                </NuxtLink>

                  <NuxtLink
                  to="/dashboard/mes-demandes-de-trajets"
                  @click="close()"
                  class="hover:bg-background-3 border-b px-5 py-3 text-[0.75rem]"
                >
                  <icon icon="route" class="mr-3 h-4 w-4"></icon>
                  Mes trajets proposés
                </NuxtLink>

                <NuxtLink
                  to="/dashboard/mes-alertes"
                  @click="close()"
                  class="hover:bg-background-3 border-b px-5 py-3 text-[0.75rem]"
                >
                  <icon icon="cloche-alerte" class="mr-3 h-4 w-4"></icon>
                  Mes alertes
                </NuxtLink>

                <NuxtLink
                  @click="
                    authStore.logout();
                    close();
                  "
                  class="hover:bg-background-3 flex cursor-pointer items-center border-b px-5 py-3 text-[0.75rem]"
                >
                  <i class="pi pi-power-off mr-3"></i>
                  Se déconnecter
                </NuxtLink>
              </PopoverPanel>
            </transition>
          </Popover>
        </section-container>

        <section-container
          v-else
          mobile-padding
          class="max-w-container flex items-center justify-between py-3"
        >
          <div>
            <a
              class="mr-5 text-xl font-bold text-white"
              href="tel:+33767473005"
            >
              <icon icon="tel" class="mr-3 h-8 w-8"></icon> 07 67 47 30 05
            </a>
            <a
              href="mailto:contact@funargos.fr"
              class="text-xl font-bold text-white"
            >
              <icon icon="mail" class="mr-3 h-8 w-8"></icon> contact@funargos.fr
            </a>
          </div>
          <span class="flex gap-4">
            <NuxtLink
              class="hover:text-primary flex h-full items-center font-semibold uppercase transition-colors"
              to="/auth/login"
            >
              <rounded-button design="2" size="md">Se connecter</rounded-button>
            </NuxtLink>
            <NuxtLink
              class="hover:text-primary flex h-full items-center font-semibold uppercase transition-colors"
              to="/auth/register"
            >
              <rounded-button design="1" size="md">Inscription</rounded-button>
            </NuxtLink>
          </span>
        </section-container>
      </section-wrapper>
    </ClientOnly>

    <section-wrapper class="bg-white">
      <section-container
        mobile-padding
        class="max-w-container flex h-32 items-center max-xl:h-24"
      >
        <NuxtLink to="/">
          <img
            class="mr-10 max-xl:w-20"
            src="/images/logo-couleur.svg"
            alt="logo"
          />
        </NuxtLink>
        <div class="flex h-full items-center text-[0.938rem] font-medium">
          <NuxtLink
            class="hover:text-primary flex h-full items-center px-5 transition-colors"
            to="/"
            >Accueil
          </NuxtLink>
          <NuxtLink
            class="hover:text-primary flex h-full items-center px-5 transition-colors"
            to="/nos-services"
            >Nos services
          </NuxtLink>

          <NuxtLink
            class="hover:text-primary flex h-full items-center px-5 transition-colors"
            href="/notre-concept"
            >Notre concept
          </NuxtLink>
        </div>
        <div class="grow"></div>

        <div class="flex h-full items-center gap-10 font-medium">
          <NuxtLink
            class="hover:text-primary flex h-full items-center font-semibold uppercase transition-colors"
            to="/publier-un-trajet"
          >
            <icon icon="plus" class="text-secondary mr-3 h-6 w-6"></icon>
            Publier un trajet
          </NuxtLink>
          <NuxtLink
            class="hover:text-primary flex h-full items-center font-semibold uppercase transition-colors"
            to="/rechercher-un-trajet"
          >
            <icon icon="loupe" class="text-ternary mr-3 h-6 w-6"></icon>
            Rechercher un trajet
          </NuxtLink>
        </div>
      </section-container>
    </section-wrapper>
  </div>
  <div>
    <div class="h-[5rem] w-full" v-if="isMobileNavbar"></div>
    <Transition
      style="animation-duration: 300ms"
      :enter-active-class="
        !isMobileNavbar ? 'animate__animated animate__slideInDown' : ''
      "
      :leave-active-class="
        !isMobileNavbar ? 'animate__animated animate__slideOutUp' : ''
      "
    >
      <div
        v-if="shouldDisplayMobileNavbar"
        class="fixed top-0 z-30 flex w-full items-center justify-between bg-white px-5"
      >
        <NuxtLink to="/">
          <img
            class="mr-10 h-20 w-20"
            src="/images/logo-couleur.svg"
            alt="logo"
          />
        </NuxtLink>

        <div
          class="flex h-full items-center text-[0.938rem] font-medium max-[1080px]:hidden"
        >
          <NuxtLink
            class="hover:text-primary flex h-full items-center px-5 transition-colors"
            to="/"
            >Accueil
          </NuxtLink>
          <NuxtLink
            class="hover:text-primary flex h-full items-center px-5 transition-colors"
            to="/nos-services"
            >Nos services
          </NuxtLink>
          <NuxtLink
            class="hover:text-primary flex h-full items-center px-5 transition-colors"
            href="/notre-concept"
            >Notre concept
          </NuxtLink>
        </div>
        <div class="grow"></div>

        <div
          class="mr-10 flex h-full items-center gap-10 font-medium max-[1080px]:hidden"
        >
          <NuxtLink
            class="hover:text-primary flex h-full items-center font-semibold uppercase transition-colors"
            to="/publier-un-trajet"
          >
            <icon icon="plus" class="text-secondary mr-3 h-6 w-6"></icon>
            Publier un trajet
          </NuxtLink>
          <NuxtLink
            class="hover:text-primary flex h-full items-center font-semibold uppercase transition-colors"
            to="/rechercher-un-trajet"
          >
            <icon icon="loupe" class="text-ternary mr-3 h-6 w-6"></icon>
            Rechercher un trajet
          </NuxtLink>
        </div>

        <img
          class="cursor-pointer min-[1080px]:hidden"
          :src="menuOpen ? '/images/CLOSE.svg' : '/images/BURGER.svg'"
          @click="menuOpen = !menuOpen"
          alt="burger"
        />
      </div>
    </Transition>

    <Transition
      style="animation-duration: 300ms"
      enter-active-class="animate__animated animate__slideInDown"
      leave-active-class="animate__animated animate__slideOutUp"
    >
      <div
        v-if="menuOpen && shouldDisplayMobileNavbar"
        class="bg-primary fixed bottom-0 left-0 right-0 top-0 z-20 flex h-full w-full flex-col"
      >
        <div class="flex grow items-center justify-center">
          <div
            class="text-primary-lighter flex flex-col gap-2 text-center text-[1rem] font-semibold"
          >
            <NuxtLink
              @click="menuOpen = false"
              to="/"
              :class="`${
                currentRoute.name == 'index'
                  ? 'text-[1.65rem]'
                  : 'text-opacity-40'
              } text-white hover:text-white`"
              >Accueil
            </NuxtLink>
            <NuxtLink
              @click="menuOpen = false"
              to="/nos-services"
              :class="`${
                currentRoute.name == 'nos-services'
                  ? 'text-[1.65rem]'
                  : 'text-opacity-40'
              } text-white hover:text-white`"
              >Nos services
            </NuxtLink>
            <NuxtLink
              @click="menuOpen = false"
              to="/notre-concept"
              :class="`${
                currentRoute.name == 'notre-concept'
                  ? 'text-[1.65rem]'
                  : 'text-opacity-40'
              } text-white hover:text-white`"
              >Notre concept
            </NuxtLink>
            <div v-if="authStore.isAuthenticated">
              <NuxtLink
                @click="menuOpen = false"
                to="/dashboard"
                :class="`${
                  currentRoute.name == 'logout'
                    ? 'text-[1.65rem]'
                    : 'text-opacity-40'
                } text-white-400 hover:text-white`"
              >
                Tableau de bord
              </NuxtLink>
            </div>
            <div v-if="authStore.isAuthenticated">
              <NuxtLink
                @click="
                  authStore.logout();
                  menuOpen = false;
                "
                :class="`${
                  currentRoute.name == 'logout'
                    ? 'text-[1.65rem]'
                    : 'text-opacity-40'
                } text-red-400 hover:text-white`"
              >
                Se déconnecter
              </NuxtLink>
            </div>
            <div v-if="!authStore.isAuthenticated">
              <NuxtLink
                @click="menuOpen = false"
                to="/auth/login"
                :class="`${
                  currentRoute.name == 'logout'
                    ? 'text-[1.65rem]'
                    : 'text-opacity-40'
                } text-white-400 hover:text-white`"
              >
                Se connecter
              </NuxtLink>
            </div>
          </div>
        </div>

        <NuxtLink
          @click="menuOpen = false"
          to="/publier-un-trajet"
          class="bg-secondary hover:bg-secondary-light cursor-pointer px-5 py-6 text-center font-semibold uppercase text-white"
        >
          <icon icon="plus" class="mr-2 h-6 w-6"></icon>
          Publier un trajet
        </NuxtLink>
        <NuxtLink
          @click="menuOpen = false"
          to="/rechercher-un-trajet"
          class="bg-ternary hover:bg-ternary-light cursor-pointer px-5 py-6 text-center font-semibold uppercase text-white"
        >
          <icon icon="loupe" class="mr-2 h-6 w-6"></icon>
          Rechercher un trajet
        </NuxtLink>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { useWindowScroll } from "@vueuse/core";
import { useElementSize } from "@vueuse/core";
import { useMediaQuery } from "@vueuse/core";
import { useRouter } from "vue-router";
import { useAuthStore } from "~/store/auth";
import { ref, computed } from "vue";

const popoverOpen = ref(false);

const el = ref(null);
const { height } = useElementSize(el);
const menuOpen = ref(false);
const { y } = useWindowScroll();

const isMobileNavbar = useMediaQuery("(max-width: 1024px)");

const router = useRouter();

const currentRoute = router.currentRoute;

const authStore = useAuthStore();

const isOutOfScroll = computed(() => {
  return y.value > height.value;
});

const shouldDisplayMobileNavbar = computed(() => {
  return isOutOfScroll.value || isMobileNavbar.value;
});
</script>
<style>

.icon-circle {
 display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    background-color: white;
    border-radius: 50%;
}
</style>